<template>

	<!-- Projects Table Column -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">Goals</h5>
				</a-col>
				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
					<a-button  shape="round" @click="addGoal" icon="plus" type="primary">
						Add goal
					</a-button>
				</a-col>
			</a-row>
		</template>
		<a-table :columns="columns" :data-source="data" :pagination="true" v-show="data.length">		

			<template slot="editBtn" slot-scope="row">
				<a-button shape="circle" @click="editgoal(row)" icon="edit">
				</a-button>
			</template>

			<template slot="delBtn" slot-scope="row">
				<a-button shape="circle" @click="delgoal(row)" icon="delete">
				</a-button>
			</template>

			<template slot="navBtn" slot-scope="row">
				<a-button shape="circle" @click="goaldetail(row)" icon="right">
				</a-button>
			</template>

		</a-table>

		
	</a-card>
	<!-- / Projects Table Column -->

</template>

<script>
	import {deletegoal} from '@/api/goal';

	export default ({
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
			goaltype: String
		},
		data() {
			return {
				// Active button for the "Projects" table's card header radio button group.
				projectHeaderBtns: 'all',
			}
		},
		methods: {
			addGoal(){
				this.$router.push({path:'/goaledit',query:{goalid:0}});
			},
			// function: goal-03
			editgoal(id){
				if (this.goaltype=="2")
					this.$router.push({path:'/goaledit',query:{goalid:id,type:2}})
				else
					this.$router.push({path:'/goaledit',query:{goalid:id}})
    
			},
			// function: goal-05
			goaldetail(id){
				if (this.goaltype=="2")
					this.$router.push({path:'/goaldetail',query:{goalid:id,type:2}})
				else
					this.$router.push({path:'/goaldetail',query:{goalid:id}})

			},
			// function: goal-04
			delgoal(id){
				deletegoal(id).then(data=>{
					for (var i=0; i < this.data.length; i++)
						if (this.data[i].goalID==id)
							this.data.splice(i,1);
				})
			}
		}
	})

</script>